
import { getWwwEventById, getWwwEvents, saveWwwEvent } from '../../api/www.api'
import { ToastLife, ToastSeverities } from '../../models/components/toast/enums'
import { SiteEvent } from '../../models/www/SiteEvent'
import EditEventDialog from '../../components/www/EditEventDialog.vue'
import { FilterMatchMode } from 'primevue/api'
import DataTable from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import { defineComponent, onMounted, ref } from 'vue'

const emptySiteEvent: () => SiteEvent = () => ({
  id: '',
  title: '',
  description: '',
  startDate: new Date(),
  visibleDate: new Date(),
  state: 'Draft',
  tags: [],
  speakers: [],
  typeForm: '',
  countries: [],
  isOnline: true,
  type: ''
})

export default defineComponent({
  name: 'SiteEventsListView',
  components: {
    EditEventDialog,
    DataTable
  },
  setup () {
    const toast = useToast()
    const loading = ref<boolean>(false)
    const expandedRows = ref<Array<SiteEvent>>([])
    const events = ref<Array<SiteEvent>>([])
    const showEditDialog = ref<boolean>(false)
    const editingEvent = ref<SiteEvent>(emptySiteEvent())
    const showPreview = ref<boolean>(false)

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const switchPreview = () => {
      showPreview.value = !showPreview.value

      if (showPreview.value) {
        expandedRows.value = events.value.filter(p => p.id)
      } else {
        expandedRows.value = []
      }
    }

    const addNew = () => {
      editingEvent.value = emptySiteEvent()

      showEditDialog.value = true
    }

    const onRemove = (event: SiteEvent) => {
      console.log(`${event.title}`)
    }

    const onEdit = async (event: SiteEvent) => {
      if (!event?.id) return
      const readEvent = await getWwwEventById(event.id)
      if (readEvent) {
        editingEvent.value = readEvent
        showEditDialog.value = true
      }
    }

    const onCancelEditDialog = () => {
      showEditDialog.value = false
    }

    async function updateEventList (): Promise<void> {
      loading.value = true
      events.value = await getWwwEvents()
      await getWwwEvents()
      loading.value = false
    }

    const onSaveEvent = async (event: SiteEvent) => {
      try {
        await saveWwwEvent(event)
        showEditDialog.value = false
        await updateEventList()
      } catch (e: any) {
        toast.add({
          summary: 'Error al grabar el evento',
          detail: e.message,
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }

    onMounted(updateEventList)

    return {
      addNew,
      events,
      editingEvent,
      expandedRows,
      filters,
      loading,
      onCancelEditDialog,
      onEdit,
      onRemove,
      onSaveEvent,
      showEditDialog,
      showPreview,
      switchPreview
    }
  }
})
